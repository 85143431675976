import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import useAuth from "../../hooks/useAuth";
import React, { useCallback, useEffect, useState } from "react";
import api from "../../services/api";
import handleConnectionError from "../../utils/handleConnectionError";
import IInvitationSent from "../../interfaces/invitations/IInvitationSent";

import { formatDistance } from 'date-fns';
import { Spinner } from "react-bootstrap";
import BadgeStatus from "../../components/BadgeStatus";
import addNotification from "../../utils/notifications";
import IBackendMessage from "../../interfaces/IBackendMessage";
import { FaSyncAlt } from "react-icons/fa";
import ButtonInline from "../../components/ButtonInline";
import { useAppTranslation } from "../../contexts/TranslationContext";

const InvitationsSent: React.FC = () => {
  const { axiosAuthToken } = useAuth()
  const { Translate, dateFnsLocale } = useAppTranslation();

  const [loadingInvitations, setLoadingInvitations] = useState(false);
  const [sendingInvitation, setSendingInvitation] = useState(false);

  const [email, setEmail] = useState('');
  const [invitationsSent, setInvitationsSent] = useState<IInvitationSent[]>([]);
  const [invitationMarkedForCancelation, setInvitationMarkedForCancelation] = useState<IInvitationSent | null>(null);

  const getInvitationsSent = useCallback(async () => {
    try {
      setLoadingInvitations(true);
      const response = await api.get<IInvitationSent[]>('/company/invitations-sent', axiosAuthToken);
      setInvitationsSent(response.data);
    }
    catch (err) {
      handleConnectionError(err);
    }
    finally {
      setLoadingInvitations(false);
    }
  }, []);

  const handleSubmitInvitation = async (event: React.FormEvent) => {
    event.preventDefault();

    setSendingInvitation(true);

    try {
      if (email === '') throw new Error(Translate('validations.email-empty'));

      const response = await api.post<IInvitationSent>('/company/employee-invitation-send', { "user_email": email }, axiosAuthToken);

      setInvitationsSent([...invitationsSent, response.data]);
      setEmail('');
      addNotification({ title: Translate('toast.done'), message: Translate('toast.invite-sent'), type: 'success' });

    }
    catch (err) {
      handleConnectionError(err);
    }
    finally {
      setSendingInvitation(false);
    }
  };

  const handleCancelPendingInvitation = useCallback(async (invitation: IInvitationSent) => {
    try {

      setInvitationMarkedForCancelation(invitation);
      const response = await api.delete<IBackendMessage>(`/company/employee-invitation-cancel-pending/${invitation.id}`, axiosAuthToken);
      addNotification({ title: Translate('toast.done'), message: response.data.message, type: 'success' });

      const updatedInvitations = invitationsSent.filter(currentInvitation => currentInvitation.id !== invitation.id);
      setInvitationsSent(updatedInvitations);
    }
    catch (err) {
      handleConnectionError(err);
    }
    finally {
      setInvitationMarkedForCancelation(null);
    }
  }, [Translate, axiosAuthToken, invitationsSent]);

  useEffect(() => {
    getInvitationsSent();
  }, []);

  return (
    <React.Fragment>
      <h3>{Translate('labels.new-invite')}</h3>

      <Form onSubmit={handleSubmitInvitation}>
        <Form.Row className="align-items-center">
          <Col xs="auto" md={3}>
            <Form.Control
              className="mb-2"
              disabled={sendingInvitation}
              id="email"
              type="email"
              placeholder={Translate('labels.email-demo')}
              value={email}
              onChange={({ target }) => setEmail(target.value)} />
          </Col>
          <Col xs="auto">

            <Button type="submit" className="mb-2" disabled={sendingInvitation}>
              {sendingInvitation ? Translate('progress.sending') : Translate('actions.send-invite')}
            </Button>

            {sendingInvitation && <Spinner as="span" animation="border" size="sm" className="ml-2" />}
          </Col>
        </Form.Row>
      </Form>

      <h3 className="mt-3">
        <span className="mr-2">{Translate('labels.invites-sent')}</span>
        <ButtonInline handleClick={() => getInvitationsSent()} disabled={loadingInvitations}>
          {!loadingInvitations ? <FaSyncAlt /> : <span>{Translate('progress.updating')}</span>}
        </ButtonInline>
      </h3>
      <p>{Translate('messages.invites-auto-delelete-after-7-days')}</p>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>{Translate('labels.name')}</th>
            <th>{Translate('labels.email')}</th>
            <th>Status</th>
            <th>{Translate('labels.sent')}</th>
          </tr>
        </thead>
        <tbody>
          {invitationsSent.map((invitation, index) => (
            <tr key={index}>

              <td>{invitation.employee.name}</td>

              <td>{invitation.employee.email}</td>

              <td>
                <BadgeStatus value={invitation.accepted} textForTrue={Translate('status.accepted')} textForFalse={Translate('status.denied')} textForNull={Translate('status.waiting-response')} />

                {invitationMarkedForCancelation?.id === invitation.id && <Spinner as="span" animation="border" size="sm" className="ml-2" />}

                {
                  invitationMarkedForCancelation?.id !== invitation.id &&
                  invitation.accepted === null &&
                  <a href="javascript:void(0);" className="ml-2" onClick={() => handleCancelPendingInvitation(invitation)}>
                    (Cancelar)
                  </a>
                }

              </td>

              <td>{formatDistance(new Date(invitation.created_at), new Date(), { addSuffix: true, locale: dateFnsLocale })}</td>
            </tr>
          ))}

          {
            loadingInvitations && (
              <tr className="text-center">
                <td colSpan={100}><Spinner as="span" animation="border" size="sm" /> {Translate('labels.loading')}</td>
              </tr>
            )
          }

          {
            !loadingInvitations && invitationsSent.length === 0 && (
              <tr className="text-center">
                <td colSpan={100}>{Translate('labels.no-invites-sent')}</td>
              </tr>
            )
          }

        </tbody>
      </Table>

    </React.Fragment >
  )
};

export default InvitationsSent;
