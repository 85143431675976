import { useEffect, useMemo, useState } from "react";
import format from "date-fns/format";
import { differenceInDays } from "date-fns";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FaPlusSquare } from "react-icons/fa";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import ptBR from 'date-fns/locale/pt-BR';
import enUS from 'date-fns/locale/en-US';
import esES from 'date-fns/locale/es';

import DatePicker, { registerLocale } from "react-datepicker";
import InputMask from "react-input-mask";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import PhoneInput from 'react-phone-number-input';
import SwitchWithLabel from "../../components/SwitchWithLabel";
import useUsers from "../../hooks/useUsers";
import IUser from "../../interfaces/IUser";
import api from "../../services/api";
import useAuth from "../../hooks/useAuth";
import IConnectionMessage from "../../interfaces/IConnectionMessage";
import handleConnectionError from "../../utils/handleConnectionError";
import addNotification from "../../utils/notifications";
import IConnectionHistory from "../../interfaces/IConnectionHistory";
import IConnectionHistoryItem from "../../interfaces/IConnectionHistoryItem";
import ICheckinHistory from "../../interfaces/ICheckinHistory";
import ICheckinHistoryItem from "../../interfaces/ICheckinHistoryItem";
import { useAppTranslation } from "../../contexts/TranslationContext";
import { formatPhoneNumberToE164Standard } from "../../utils/numbers";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";

registerLocale('pt', ptBR);
registerLocale('en', enUS);
registerLocale('es', esES);

const UserEdit: React.FC = () => {

  const { Translate, selectedLanguage, getLanguageCodeToReactPhoneNumberInput } = useAppTranslation();

  const schema = useMemo(() => {
    return yup
      .object({
        name: yup.string().required(Translate('validations.name-required')),
        company: yup.string().required(Translate('validations.company-required')),
        country: yup.string().required(Translate('validations.country-required')),
        region: yup.string().required(Translate('validations.region-required')),
        address: yup.string().required(Translate('validations.address-required')),
        email: yup
          .string()
          .email(Translate('validations.email-invalid'))
          .required(Translate('validations.email-required')),
        phone: yup.string().required(Translate('validations.phone-required')),
        person_type: yup.string().required(Translate('validations.person-type-required')),
        expires_at: yup.string().required(),
        list_enabled: yup.bool().required("Informe se o List está liberado."),
        sisbot_enabled: yup.bool().required("Informe se o SISBot está liberado."),
        annotations: yup.string().nullable(),
        sisbolt_notification: yup.string().max(255).nullable(),

        max_employees: yup.number()
          .typeError(Translate('validations.number-type-error'))
          .integer(Translate('validations.number-integer'))
          .positive(Translate('validations.number-positive'))
          .min(1, Translate('validations.number-min1')),

        simulator_projects_max_quota: yup.number()
          .typeError(Translate('validations.number-type-error'))
          .integer(Translate('validations.number-integer'))
          .positive(Translate('validations.number-positive'))
          .min(1, Translate('validations.number-min1')),

        simulator_max_cliparts: yup.number()
          .typeError(Translate('validations.number-type-error'))
          .integer(Translate('validations.number-integer'))
          .positive(Translate('validations.number-positive'))
          .min(1, Translate('validations.number-min1')),

        simulator_max_models: yup.number()
          .typeError(Translate('validations.number-type-error'))
          .integer(Translate('validations.number-integer'))
          .positive(Translate('validations.number-positive'))
          .min(1, Translate('validations.number-min1')),

        list_projects_max_quota: yup.number()
          .typeError(Translate('validations.number-type-error'))
          .integer(Translate('validations.number-integer'))
          .positive(Translate('validations.number-positive'))
          .min(1, Translate('validations.number-min1')),

        simulator_enabled: yup
          .bool()
          .required("Informe se o Simulador Básico está liberado."),

        simulator_ar_enabled: yup
          .bool()
          .required("Informe se o Simulador AR está liberado."),

        cpf: yup
          .string()
          .nullable()
          .when(["person_type"], {
            is: (person_type: string) => person_type === "legal_person",
            then: yup.string().required(Translate('validations.cpf-required')),
          }),

        cnpj: yup
          .string()
          .nullable()
          .when(["person_type"], {
            is: (person_type: string) => person_type === "juridical_person",
            then: yup.string().required(Translate('validations.cnpj-required')),
          }),

        other_identification: yup
          .string()
          .nullable()
          .when(["person_type"], {
            is: (person_type: string) => person_type === "other_identification",
            then: yup.string().required(Translate('validations.other-identification-required')),
          }),

        mac_address: yup.string().nullable(),

        machine_id: yup.string().nullable(),

        payment_recurrency_type: yup.string().oneOf(['mensal', 'anual']).required("Tipo inválido de recorrência de pagamento."),

        can_use_exclusive_model_pricing: yup
          .bool()
          .required("Informe se o acesso aos preços exclusivos está liberado."),
      })
      .required();
  }, [Translate]);

  const { axiosAuthToken } = useAuth();
  const { findUserById } = useUsers();
  const [searchParams] = useSearchParams();
  const [userId, setUserId] = useState<string | null>(null);
  const [deletingClient, setDeletingClient] = useState(false);
  const [connectionHistory, setConnectionHistory] = useState<IConnectionHistory | null>(null);
  const [checkinsHistory, setCheckinsHistory] = useState<ICheckinHistory | null>(null);
  const [loadingConnHistory, setLoadingConnHistory] = useState(false);
  const [loadingCheckinsHistory, setLoadingCheckinsHistory] = useState(false);
  const [showConnectionHistory, setShowConnectionHistory] = useState(false);
  const [showCheckinsHistory, setShowCheckinsHistory] = useState(false);
  const [connectionHistoryPage, setConnectionHistoryPage] = useState(1);
  const [connectionHistoryData, setConnectionHistoryData] = useState<IConnectionHistoryItem[]>([]);

  const [checkinsHistoryData, setCheckinsHistoryData] = useState<ICheckinHistoryItem[]>([]);

  const [connectionCheckinPage, setConnectionCheckinPage] = useState(1);

  const navigate = useNavigate();

  const { dateFnsLocale } = useAppTranslation();

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<IUser>({ resolver: yupResolver(schema) });

  const watchPersonType = watch("person_type", "");
  const watchCountry = watch("country", "");

  const handleUpdate: SubmitHandler<IUser> = async (userdata) => {
    try {
      const response = await api.put<IConnectionMessage>(
        `/users/${userdata.id}`,
        userdata,
        axiosAuthToken
      );

      const { message } = response.data;

      addNotification({
        title: Translate('toast.done'),
        message: message,
        type: "success",
      });
    } catch (err) {
      handleConnectionError(err);
    }
  };

  const handleDeleteClient = async () => {
    const confirm = window.confirm(Translate('message.confirm-client-remove'));

    if (!confirm) return;

    try {
      const response = await api.delete<IConnectionMessage>(
        `/users/${userId}`,
        axiosAuthToken
      );

      addNotification({
        title: Translate('toast.removed-success'),
        message: response.data.message,
        type: "success",
      });

      navigate("/admin/users");
    } catch (err) {
      handleConnectionError(err);
    } finally {
      setDeletingClient(false);
    }
  };

  const handleAddMacAddress = (mac: string) => {
    const currentMacList = getValues('mac_address');

    if (currentMacList.includes(mac)) {
      addNotification({
        title: Translate('toast.already-exists'),
        message: Translate('messages.mac-already-authorized'),
        type: "info",
      });
      return;
    }

    setValue("mac_address", `${currentMacList}\n${mac}`);
    setShowConnectionHistory(false);

    addNotification({
      title: Translate('toast.added'),
      message: Translate('messages.mac-was-authorized'),
      type: "info",
    });
  };

  const loadConnectionHistoryData = () => {
    const userId = searchParams.get("id");

    setLoadingConnHistory(true);
    api
      .get<IConnectionHistory>(
        `/users/connection_history/${userId}?page=${connectionHistoryPage}`,
        axiosAuthToken
      )
      .then((response) => {
        const { data } = response.data;
        setConnectionHistory(response.data);
        setConnectionHistoryData([...connectionHistoryData, ...data]);
      })
      .catch((err) => {
        setShowConnectionHistory(false);
        handleConnectionError(err);
      })
      .finally(() => {
        setLoadingConnHistory(false);
      });
  };

  const loadCheckinsHistoryData = () => {
    const userId = searchParams.get("id");

    setLoadingCheckinsHistory(true);
    api
      .get<ICheckinHistory>(
        `/users/checkin/${userId}?page=${connectionCheckinPage}`,
        axiosAuthToken
      )
      .then((response) => {
        const { data } = response.data;
        setCheckinsHistory(response.data);
        setCheckinsHistoryData([...checkinsHistoryData, ...data]);
      })
      .catch((err) => {
        setShowCheckinsHistory(false);
        handleConnectionError(err);
      })
      .finally(() => {
        setLoadingCheckinsHistory(false);
      });
  }

  const handleShowConnectionHistory = () => {
    if (connectionHistory === null) loadConnectionHistoryData();
    setShowConnectionHistory(true);
  };

  const handleShowCheckIns = () => {
    if (checkinsHistory === null) loadCheckinsHistoryData();
    setShowCheckinsHistory(true);
  }

  useEffect(() => loadConnectionHistoryData(), [connectionHistoryPage]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "person_type" && type === "change") {
        setValue("cpf", "");
        setValue("cnpj", "");
        setValue("other_identification", "");
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    const userId = searchParams.get("id");

    if (!userId) {
      addNotification({
        title: Translate('toast.error'),
        message: Translate('messages.invalid-id'),
        type: "warning",
      });

      navigate("/usuarios");
      return;
    }

    const user = findUserById(Number.parseInt(userId));

    if (!user) {
      addNotification({
        title: Translate('toast.error'),
        message: Translate('toast.user-not-found'),
        type: "warning",
      });

      navigate("/usuarios");
      return;
    }

    setUserId(userId);
    reset(user);
  }, []);

  return (
    <>
      <Modal show={showCheckinsHistory} onHide={() => setShowCheckinsHistory(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Check-ins</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {checkinsHistory === null && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}

          {checkinsHistory !== null && checkinsHistoryData.length === 0 && (
            <p>{Translate('messages.no-records-found')}</p>
          )}

          {checkinsHistory !== null && checkinsHistoryData.length > 0 && (
            <>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr style={{ width: 200 }}>
                    <th style={{ textAlign: 'center' }}>{Translate('labels.machine')}</th>
                    <th style={{ textAlign: 'center' }}>{Translate('labels.use-time')}</th>
                  </tr>
                </thead>
                <tbody>
                  {checkinsHistoryData.map((item, index) => (
                    <tr key={index}>
                      <td className="text-center align-middle">
                        {item.machine_id.substring(0, 15) + '...' + item.machine_id.slice(-15)}
                      </td>
                      <td className="text-center align-middle">
                        {format(
                          new Date(item.created_at),
                          "dd-MM-yyyy HH:mm:ss"
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              {connectionCheckinPage < checkinsHistory.last_page && (
                <Button
                  variant="primary"
                  size="sm"
                  block
                  disabled={loadingCheckinsHistory}
                  onClick={() => {
                    if (!loadingCheckinsHistory) {
                      setConnectionCheckinPage(connectionCheckinPage + 1);
                      loadCheckinsHistoryData();
                    }
                  }}
                >
                  {!loadingCheckinsHistory ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <FaPlusSquare />
                      <span className="ml-1">
                        <span>{Translate('labels.load-more')}</span>
                      </span>
                    </div>
                  ) : (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </Button>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowCheckinsHistory(false)}
          >
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showConnectionHistory}
        onHide={() => setShowConnectionHistory(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{Translate('labels.connection-history')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {connectionHistory === null && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}

          {connectionHistory !== null && connectionHistoryData.length === 0 && (
            <p>{Translate('messages.no-records-found')}</p>
          )}

          {connectionHistory !== null && connectionHistoryData.length > 0 && (
            <>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>MAC</th>
                    <th>Status</th>
                    <th>{Translate('labels.date')}</th>
                  </tr>
                </thead>
                <tbody>
                  {connectionHistoryData.map((item) => (
                    <tr key={item.id}>
                      <td>
                        <Button
                          variant="link"
                          onClick={() => handleAddMacAddress(item.mac_address)}
                        >
                          {item.mac_address}
                        </Button>
                      </td>
                      <td className="text-center align-middle">
                        <Badge variant={item.successful ? "success" : "danger"}>
                          {item.successful ? "OK" : Translate('status.failed')}
                        </Badge>
                      </td>
                      <td className="text-center align-middle">
                        {format(
                          new Date(item.created_at),
                          "dd-MM-yyyy HH:mm:ss"
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              {connectionHistoryPage < connectionHistory.last_page && (
                <Button
                  variant="primary"
                  size="sm"
                  block
                  disabled={loadingConnHistory}
                >
                  {!loadingConnHistory ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <FaPlusSquare />
                      <span
                        className="ml-1"
                        onClick={() => {
                          if (!loadingConnHistory)
                            setConnectionHistoryPage(connectionHistoryPage + 1);
                        }}
                      >
                        <span>{Translate('actions.load-more')}</span>
                      </span>
                    </div>
                  ) : (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </Button>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowConnectionHistory(false)}
          >
            {Translate('actions.close')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Form onSubmit={handleSubmit(handleUpdate)} noValidate>
        <Row className="mb-5">
          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <h2 className="mb-3">{Translate('labels.registration-data')}</h2>

            <Row>
              <Col>
                <Form.Group controlId="name">
                  <Form.Label>{Translate('labels.full-name')}</Form.Label>
                  <Form.Control
                    isInvalid={Object.keys(errors).includes("name")}
                    type="text"
                    readOnly={isSubmitting}
                    {...register("name")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="company">
                  <Form.Label>{Translate('labels.company-name')}</Form.Label>
                  <Form.Control
                    isInvalid={Object.keys(errors).includes("company")}
                    type="text"
                    readOnly={isSubmitting}
                    {...register("company")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.company?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group controlId="country">
                  <Form.Label>{Translate('labels.country')}</Form.Label>
                  <Controller
                    control={control}
                    name="country"
                    render={({ field: { value, onChange } }) => (
                      <CountryDropdown
                        defaultOptionLabel={Translate('labels.select')}
                        value={value}
                        onChange={onChange}
                        classes="form-control" />
                    )}
                  />

                  {!!errors.country && <div className="invalid-feedback d-block">{errors.country.message}</div>}
                </Form.Group>
              </Col>

              <Col>
                <Form.Group controlId="region">
                  <Form.Label>{Translate('labels.region')}</Form.Label>
                  <Controller
                    control={control}
                    name="region"
                    render={({ field: { value, onChange } }) => (
                      <RegionDropdown
                        defaultOptionLabel={Translate('labels.select')}
                        disableWhenEmpty
                        country={watchCountry}
                        value={value}
                        onChange={onChange}
                        classes="form-control" />
                    )}
                  />

                  {!!errors.region && <div className="invalid-feedback d-block">{errors.region.message}</div>}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group controlId="address">
                  <Form.Label>{Translate('labels.address')}</Form.Label>
                  <Form.Control
                    isInvalid={Object.keys(errors).includes("address")}
                    type="text"
                    readOnly={isSubmitting}
                    {...register("address")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.address?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group controlId="email">
                  <Form.Label>{Translate('labels.email')}</Form.Label>
                  <Form.Control
                    isInvalid={Object.keys(errors).includes("email")}
                    type="text"
                    readOnly={isSubmitting}
                    {...register("email")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col>
                <Form.Group controlId="phone">
                  <Form.Label>{Translate('labels.phone')}</Form.Label>
                  <Controller
                    control={control}
                    name="phone"
                    render={({ field: { value, onChange } }) => (
                      <div className="form-group">

                        <PhoneInput
                          disabled={isSubmitting}
                          value={formatPhoneNumberToE164Standard(value)}
                          onChange={onChange}
                          labels={getLanguageCodeToReactPhoneNumberInput(selectedLanguage)}
                          className={`form-control ${!!errors.phone ? 'is-invalid' : ''}`}
                        />

                        {
                          !!errors.phone && (
                            <div className="invalid-feedback d-block">{errors.phone.message}</div>
                          )
                        }
                      </div>
                    )}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group controlId="email">
                  <Form.Label>{Translate('labels.billing-email')}</Form.Label>
                  <Form.Control
                    isInvalid={Object.keys(errors).includes("billing_email")}
                    type="text"
                    readOnly={isSubmitting}
                    {...register("billing_email")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.billing_email?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col>
                <Form.Group controlId="user_type">
                  <Form.Label>{Translate('labels.account-type')}</Form.Label>
                  <Form.Control
                    disabled={isSubmitting}
                    as="select"
                    {...register("user_type")}
                  >
                    <option value="employee">{Translate('labels.employee')}</option>
                    <option value="company">{Translate('labels.company')}</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group controlId="person_type">
                  <Form.Label>{Translate('labels.person-type')}</Form.Label>
                  <Form.Control
                    disabled={isSubmitting}
                    as="select"
                    {...register("person_type")}
                  >
                    <option value="legal_person">{Translate('labels.legal-person')}</option>
                    <option value="juridical_person">{Translate('labels.juridical-person')}</option>
                    <option value="other_identification">{Translate('labels.other')}</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col>
                {/* CPF */}
                {watchPersonType === "legal_person" && (
                  <Form.Group controlId="cpf">
                    <Form.Label>CPF</Form.Label>
                    <Controller
                      control={control}
                      name="cpf"
                      render={({ field: { value, onChange } }) => (
                        <>
                          <Form.Control
                            disabled={isSubmitting}
                            isInvalid={Object.keys(errors).includes("cpf")}
                            as={InputMask}
                            mask="999.999.999-99"
                            value={value}
                            onChange={onChange}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.cpf?.message}
                          </Form.Control.Feedback>
                        </>
                      )}
                    />
                  </Form.Group>
                )}

                {/* CNPJ */}
                {watchPersonType === "juridical_person" && (
                  <Form.Group controlId="cnpj">
                    <Form.Label>CNPJ</Form.Label>
                    <Controller
                      control={control}
                      name="cnpj"
                      render={({ field: { value, onChange } }) => (
                        <>
                          <Form.Control
                            disabled={isSubmitting}
                            isInvalid={Object.keys(errors).includes("cnpj")}
                            as={InputMask}
                            mask="99.999.999/9999-99"
                            value={value}
                            onChange={onChange}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.cnpj?.message}
                          </Form.Control.Feedback>
                        </>
                      )}
                    />
                  </Form.Group>
                )}

                {/* other_identification */}
                {watchPersonType === "other_identification" && (
                  <Form.Group controlId="other_identification">
                    <Form.Label>{Translate('labels.unique-identifier')}</Form.Label>
                    <Controller
                      control={control}
                      name="other_identification"
                      render={({ field: { value, onChange } }) => (
                        <>
                          <Form.Control
                            disabled={isSubmitting}
                            isInvalid={Object.keys(errors).includes("other_identification")}
                            value={value}
                            onChange={onChange}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.other_identification?.message}
                          </Form.Control.Feedback>
                        </>
                      )}
                    />
                  </Form.Group>
                )}
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group controlId="machine_id">
                  <Form.Label>{Translate('labels.machine-id')}</Form.Label>
                  <Form.Control
                    isInvalid={Object.keys(errors).includes("machine_id")}
                    type="text"
                    readOnly={isSubmitting}
                    {...register("machine_id")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.machine_id?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col>
                <Form.Group controlId="payment_recurrency_type">
                  <Form.Label>{Translate('labels.payment-recurrency')}</Form.Label>
                  <Form.Control
                    disabled={isSubmitting}
                    as="select"
                    {...register("payment_recurrency_type")}
                  >
                    <option value="mensal">{Translate('labels.monthly')}</option>
                    <option value="anual">{Translate('labels.yearly')}</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group controlId="max_employees">
                  <Form.Label>{Translate('labels.employees')}</Form.Label>
                  <Form.Control
                    isInvalid={Object.keys(errors).includes("max_employees")}
                    type="text"
                    readOnly={isSubmitting}
                    {...register("max_employees")}
                  />
                  <Form.Text className="text-muted">
                    {Translate('labels.max-quantity')}
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {errors.max_employees?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col>
                <Form.Group controlId="simulator_max_cliparts">
                  <Form.Label>Cliparts</Form.Label>
                  <Form.Control
                    isInvalid={Object.keys(errors).includes("simulator_max_cliparts")}
                    type="text"
                    readOnly={isSubmitting}
                    {...register("simulator_max_cliparts")}
                  />
                  <Form.Text className="text-muted">
                    {Translate('labels.max-quantity')}
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {errors.simulator_max_cliparts?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col>
                <Form.Group controlId="simulator_max_models">
                  <Form.Label>{Translate('labels.3d-models')}</Form.Label>
                  <Form.Control
                    isInvalid={Object.keys(errors).includes("simulator_max_models")}
                    type="text"
                    readOnly={isSubmitting}
                    {...register("simulator_max_models")}
                  />
                  <Form.Text className="text-muted">
                    {Translate('labels.max-quantity')}
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {errors.simulator_max_models?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group controlId="expires_at">
                  <Form.Label>{Translate('labels.account-expiration')}</Form.Label>
                  <Controller
                    name="expires_at"
                    control={control}
                    render={({ field: { value, onChange } }) => {
                      const parsedDate =
                        value == null
                          ? new Date()
                          : new Date(Date.parse(value.toString()));

                      const currentDate = new Date();
                      const finalDate = format(parsedDate, "dd/MM/yyyy");
                      const daysLeft = differenceInDays(
                        new Date(format(parsedDate, "yyyy-MM-dd")),
                        new Date(format(currentDate, "yyyy-MM-dd"))
                      );

                      return (
                        <>
                          <Form.Control
                            isInvalid={Object.keys(errors).includes(
                              "expires_at"
                            )}
                            disabled={isSubmitting}
                            as={DatePicker}
                            locale={dateFnsLocale}
                            startDate={currentDate}
                            endDate={parsedDate}
                            value={finalDate}
                            selectsEnd
                            onChange={(date: any) => {
                              const parsedDate = date as Date;
                              onChange(parsedDate.toISOString());
                            }}
                          />
                          <Form.Text className="text-muted">
                            {daysLeft >= 0
                              ? Translate('labels.days-left-to-expire').replace('[DAYS]', daysLeft.toString())
                              : Translate('labels.account-expired')}
                          </Form.Text>
                          <Form.Control.Feedback type="invalid">
                            {errors.expires_at?.message}
                          </Form.Control.Feedback>
                        </>
                      );
                    }}
                  />
                </Form.Group>
              </Col>

              <Col>
                <Form.Group controlId="simulator_projects_max_quota">
                  <Form.Label>{Translate('labels.simulator-projects')}</Form.Label>
                  <Form.Control
                    isInvalid={Object.keys(errors).includes("simulator_projects_max_quota")}
                    type="text"
                    readOnly={isSubmitting}
                    placeholder="123"
                    {...register("simulator_projects_max_quota")}
                  />
                  <Form.Text className="text-muted">
                    {Translate('labels.max-quantity')}
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {errors.simulator_projects_max_quota?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col>
                <Form.Group controlId="list_max_projects">
                  <Form.Label>{Translate('labels.list-projects')}</Form.Label>
                  <Form.Control
                    isInvalid={Object.keys(errors).includes("list_max_projects")}
                    type="text"
                    readOnly={isSubmitting}
                    placeholder="123"
                    {...register("list_max_projects")}
                  />
                  <Form.Text className="text-muted">
                    {Translate('labels.max-quantity')}
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {errors.list_max_projects?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group controlId="mac_address">
                  <Form.Label>{Translate('labels.mac-addresses')}</Form.Label>
                  <Form.Control
                    isInvalid={Object.keys(errors).includes("mac_address")}
                    as="textarea"
                    rows={3}
                    readOnly={isSubmitting}
                    placeholder="XX:XX:XX:XX:XX:XX&#10;XX:XX:XX:XX:XX:XX&#10;..."
                    {...register("mac_address")}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.mac_address?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Col>

          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <h2 className="mb-3">{Translate('labels.systems')}</h2>

            <p>{Translate('labels.manage-user-systems')}</p>

            <Controller
              name="list_enabled"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <SwitchWithLabel
                    badgeTitle="LIST"
                    checked={value}
                    disabled={isSubmitting}
                    handleOnChange={onChange}
                  />
                );
              }}
            />

            <Controller
              name="sisbot_enabled"
              control={control}
              render={({ field: { value, onChange } }) => (
                <SwitchWithLabel
                  badgeTitle="SISBOT"
                  checked={value}
                  disabled={isSubmitting}
                  handleOnChange={onChange}
                />
              )}
            />

            <Controller
              name="sisbolt_enabled"
              control={control}
              render={({ field: { value, onChange } }) => (
                <SwitchWithLabel
                  badgeTitle="SISBOLT"
                  checked={value}
                  disabled={isSubmitting}
                  handleOnChange={onChange}
                />
              )}
            />

            <Controller
              name="simulator_enabled"
              control={control}
              render={({ field: { value, onChange } }) => (
                <SwitchWithLabel
                  badgeTitle="SIMULATOR BASIC"
                  checked={value}
                  disabled={isSubmitting}
                  handleOnChange={onChange}
                />
              )}
            />

            <Controller
              name="simulator_ar_enabled"
              control={control}
              render={({ field: { value, onChange } }) => (
                <SwitchWithLabel
                  badgeTitle="SIMULATOR AR"
                  checked={value}
                  disabled={isSubmitting}
                  handleOnChange={onChange}
                />
              )}
            />

            <h2 className="mb-3">{Translate('labels.features')}</h2>

            <p>{Translate('labels.manage-user-features')}</p>

            <Controller
              name="can_use_exclusive_model_pricing"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <SwitchWithLabel
                    badgeTitle={Translate('labels.exclusive-model-prices-by-sublist')}
                    checked={value}
                    disabled={isSubmitting || getValues('user_type') === 'employee'}
                    handleOnChange={onChange}
                  />
                );
              }}
            />

            <Row>
              <Col>
                <Form.Group controlId="sisbolt_notification">
                  <Form.Label>
                    {Translate('labels.sisbolt-notification')}
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    maxLength={255}
                    placeholder={Translate('labels.write-here')}
                    {...register("sisbolt_notification")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.sisbolt_notification?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col>
                <Form.Group controlId="annotations">
                  <Form.Label>
                    {Translate('labels.admin-notes')}
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    {...register("annotations")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.annotations?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Button variant="primary" type="submit">
              {!isSubmitting ? (
                Translate('actions.save-account-editings')
              ) : (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </Col>
        </Row>

        <Row className="mb-5">
          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <h2 className="mb-3">Check-ins</h2>

            <p>{Translate('labels.checkins-description')}</p>

            <Button variant="primary" onClick={handleShowCheckIns}>
              {Translate('labels.show-checkins')}
            </Button>
          </Col>
        </Row>

        <Row className="mb-5">
          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <h2 className="mb-3">{Translate('labels.connection-history')}</h2>

            <p>
              {Translate('messages.checkins-description')}
            </p>

            <Button variant="primary" onClick={handleShowConnectionHistory}>
              {Translate('actions.show-history')}
            </Button>
          </Col>

          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <h2 className="mb-3">{Translate('labels.account')}</h2>

            <p>{Translate('messages.delete-account-description')}</p>

            <Button
              variant="danger"
              onClick={handleDeleteClient}
              disabled={deletingClient}
            >
              {deletingClient ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                Translate('actions.delete-client')
              )}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default UserEdit;
